<template>
  <div >
   <Intersect v-if="loading" >
    <categoriesPlaceholder  />
   </Intersect>
    <Slider 
    v-else
      :screen="screen"
      :contentList="sliderContents"
      :screenName="screenName"
      :id="id"
      :title="'top'"
      :playerInstance="playerInstance"
      @reachEnd="reachEnd"
      >

    </Slider>

   

  </div>
</template>

<script>
import categoriesPlaceholder from "@/components/placeholders/categoriesPlaceholder.vue";
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import Intersect from 'vue-intersect'
import { store } from "@/store/store";

export default {
  props: {
    screen: {
      type: Object,
    },
    id: {
      type: Number,
    },
    screenName: {
      type: String,
    },
    localLang: {
      type: String,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      contentList: false,
      showSlider: true,
      sliderContents: [],
      localDisplayLang: null,
      isMpegRequired: false,
      loading: true,
      contentNotFound: true,
      currentPage: 1,
      totalCountValue: 0
    };
  },
  computed: {
    ...mapGetters(["getToken", "currentBrowser", "subscriberid"]),
  },
  created() {
    this.isMpegRequired = this.checkMpegRequired();
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.loading = true;
      this.loadCategory();
    });
  },
  mounted() {
    this.loadCategory();
  },
  methods: {
    reachEnd () {
      if( this.totalCountValue == this.sliderContents.length) return;
      this.currentPage = this.currentPage + 1;
      this.loadCategory();
    },
    filterContentList (response) {
      if (!response.reason) {
        this.loading = false;
        this.contentList = true;
        let itemCase = JSON.parse(JSON.stringify([...this.sliderContents, ...response.data]))
        this.sliderContents = itemCase;
        this.totalCountValue = response.totalcount
        let dataLength = this.sliderContents.length;
        eventBus.$emit("on-data-callback", {"index": this.id, "is_data_available": dataLength > 0});
      } else if (response.errorcode === 7109 || response.errorcode === 8812) {
        // this.contentList = true;
        // this.showSlider = false;
        // this.loading = true;
        // this.contentNotFound = false;
        // eventBus.$emit("on-data-callback", {"index": this.id, "is_data_available": false});

      }
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    getEndPoint () {
      const sectionType = this.screen.sectionType;
      const itemType = this.screen.itemType;
      
      if (sectionType == "ITEMLIST" && itemType == "CONTENT") {
        return "/subscriber/v1/content"
      } else if (sectionType == "ITEMLIST" && itemType == "TRAILERS") {
         return "/subscriber/v1/metadata/trailer"
      } else if (sectionType == "ITEMLIST" && itemType == "CASTNCREW") {
         return "/subscriber/v1/metadata/castncrew"
      }  else {
         return "/subscriber/v1/content"
      }
    },
    categoryList(data) {
      store
        .dispatch("contentList", data)
        .then((response) => {
          if (response.data) {
            if (data.type && data.type === "bold") {
              eventBus.$emit("bold-contents", response.data);
            } else {
              this.filterContentList(response.data);
            }
          }
        })
        .catch((error) => console.log(error));
    },
    loadCategory() {
      // if(!this.loading){
      //   return;
      // }
      if (this.screen) {
        let payload = {
          endpoint: this.screen.endpoint || this.getEndPoint(),
          id: this.id,
          params: this.screen.parameters || this.screen.sectionData || {}
        };

        if (this.screen.sectionType == "ITEMLIST") {
          let strItem = ''
           let item = this.screen.sectionData.map((data) => {
            // strItem += data.id + ","
            return data.id;
           })
           strItem = strItem.substring(0, strItem.length - 1);
           if (this.screen.itemType == "CONTENT") {
            payload.params = {
              contentlist : JSON.stringify(item),
              orderby: {"objectid": item}
            }
           } else {
            payload.params = {
              contentlist : JSON.stringify(item)
            }
           }
         
        }
        let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

        if (subscriberProfileDetails) {
          subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
        }

        payload.params.page = this.currentPage;
        // this.loading = true;
        // this.contentList = false;
        // this.showSlider = true;

        payload.params.displaylanguage = this.localDisplayLang;

        if (this.isMpegRequired == true) {
          payload.params.mpegtspackage = "YES";
        }

        // if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
        //   payload.params.genre = "Kids";
        // }

        // eventBus.$emit("category-list", payload);
        this.categoryList(payload)
        
      }
    },
  },
  components: {
    Intersect,
    categoriesPlaceholder,
    // categorySlider,
    Slider: () =>
      import('@/themeearth/components/dashboard/sliderCarousel/Slider.vue'),
  },
};
</script>

<style></style>
